import React, { useState } from 'react';


import {
    Badge,
    Card,
    Input,
    Button,
    Typography,
    IconButton,
    Select,
    Option,
    Textarea,
    Tooltip

} from "@material-tailwind/react";

import {
    CalendarDaysIcon,
    ArrowTopRightOnSquareIcon,
    MapIcon,
    BeakerIcon,
    InboxArrowDownIcon,
    PencilIcon,
    TrashIcon,
    RectangleGroupIcon,
    PaperClipIcon,
    XMarkIcon,
    PencilSquareIcon

} from "@heroicons/react/24/outline";


import ShapeUnziper from './ShapeUnziper.jsx';

import * as turf from '@turf/turf'

//import MapboxPreview from './MapBoxPreview.jsx';
import MapboxPreviewProps from './MapBoxPreviewProps.jsx';

//import { json } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import ConfirmDialog, { QueryDataDialog } from './ConfirmDialog.jsx';
import { showAlert } from './Alert.jsx';
import { geoportalGetParcelPolygon } from './geoportal.js';
import FileUploadCard from './FileUploadCard.jsx';
import { getField, getFieldFiles, listStorageItems } from './AdminTools.jsx';
import DoubleFieldCard from './DoubleFieldCard.jsx';
import ResultsProcessingCard from './ResultsProcessingCard.jsx';
import FieldImportCard from './FieldImportCard.jsx';
//import { features } from 'process';


export default function FieldCard(props) {

    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [field, setField] = useState({});
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem("files")) || []);

    const [drawingfeature, setDrawingFeature] = useState();

    const [results, setResults]= useState();

    const [previewFeature, setPreviewFeature]= useState();
    const [previewBBox, setPreviewBBox]= useState();


    const [ownerid, setOwnerId] = useState();
    const [clientName, setClientName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState();
    const [bbox, setBBox] = useState();
    const [center, setCenter] = useState();
    const [shape, setShape] = useState();
    const [name, setName] = useState('');
    const [crops, setCrops] = useState('');
    const [area, setArea] = useState(0);
    const [action, setAction] = useState('create');
    const [fieldid, setFieldId] = useState(0);
    const [tested, setTested] = useState();
    const [showPreview, setShowPreview] = useState(false);

    const [hidden, setHidden] = useState('');
    const [featuresCount, setFeaturesCount] = useState(0);
    const [uploaded, setUploaded] = useState(false);
    const [filter, setFilter] = useState("days");

    const [clearOnExit, setClearOnExit] = useState(false);
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmSplit, setShowConfirmSplit] = useState(false);
    const [showConfirmGroup, setShowConfirmGroup] = useState(false);
    const [showDoubleCard, setShowDoubleCard] = useState(false);
    const [showResultsProcessingCard, setShowResultsProcessingCard] = useState(false);

    const [importedFeatures, setImportedFeatures] = useState();
    const [featuresToSplit, setFeaturesToSplit] = useState();


    const [showGeoportalQuery, setShowGeoportalQuery] = useState(false);

    const [showFileCard, setShowFileCard] = useState(false);
    const [showImportCard, setShowImportCard] = useState(false);

    const [cardPosition, setCardPosition] = useState("top-[1rem] lg:left-[17rem] left-[6rem] max-h-[calc(100vh-2rem)]");

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);

    const [splitBodyData, setSplitBodyData] = useState({});

    const emptyGeoJson = {
        "type": "FeatureCollection",
        features: []
    }

    let collection = emptyGeoJson;
    let collectionToSplit = null;
    let expectFeatures = 0;
    var filterName="days";


    React.useEffect(() => {
        addEventListener('previewImageChanged', previewImageChanged);
                       

        addEventListener('featureDrawingFinished', () => {
            let feature;
            try {
                feature = JSON.parse(localStorage.getItem("drawingFeature"));
                if (feature != null) {
                    setDrawingFeature(feature);
                    showGeoJson(feature);
                    setShowPreview(true);
                    setClearOnExit(true);
                }
                setHidden('');
            }
            catch {
                setDrawingFeature(null);
                console.log('drawing feature parse failed');
            }
        });

        setOwnerId(props.clientid);
        setFieldId(props.fieldid);
        getFieldData(props.fieldid);

            return () => {
                removeEventListener('previewImageChanged', previewImageChanged);
                console.log('removing listener');
              };    

    },[]);


    React.useEffect(() => {
        //if (importedFeatures) setShowImportCard(true);
        
    }, [importedFeatures]);

    React.useEffect(() => {
        setFieldId(props.fieldid);
        getFieldData(props.fieldid);
        
    }, [props.fieldid]);



    function getFieldData(fieldid)  {
        
        listStorageItems();

        if (clients.length == 0) setCardPosition("top-[6rem] left-0 max-h-[calc(100vh-7rem)]");

        init(props);

        getField(session, gotFieldData,props.clientid,props.fieldid);

        if (props.fieldid) 
            {
                getFieldFiles(session, props.fieldid, setFiles, "json");   //// important    
                //getField(session,setField,props.clientid,props.fieldid);
            }
    }


    function previewImageChanged() {
        setImage(localStorage.getItem("previewImage"));
        console.log("Got Preview image ");
        setShowPreview(false); //close mapbox preview and show img

        let splitBody = localStorage.getItem("splitBody");
        if (splitBody) 
            {
                console.log("Got split body");
                sendPreparedBodyData();
            }
    }



    function gotFieldData(data)
        {
            
            if (data.length==1)
            {
                setField(data[0]);
                let field=data[0];
                if (field.image) {
                    setImage(field.image);
                }

                try {
                    if (field.shape.features[0].type == "FeaturesCollection") { field.shape = shape.features[0]; console.log('unpack fc'); };
                    setShape(JSON.parse(field.shape));
                } catch { };
                if (field.shape) {
                    //add a bit of delay for better rendering
                    showGeoJson(JSON.parse(field.shape), false);
                }
            
            }
        }



    function init(props) {
        if (props.fieldid > 0) {
            setAction('update');

            let fieldarray = fields.filter(f => f.id == props.fieldid);
            if (fieldarray.length == 1) {
                let field = fieldarray[0];
                setOwnerId(field.owner);
                setName(field.name);
                setCrops(field.crops);
                setDescription(field.description);
                setArea(field.area);
                if (field.image) {
                    setImage(field.image);
                }

                try {
                    setTested(JSON.parse(field.tested));
                } catch { }

                try {
                    if (field.shape.features[0].type == "FeaturesCollection") { field.shape = shape.features[0]; console.log('unpack fc'); };
                    setShape(JSON.parse(field.shape));
                } catch { };
                if (field.shape) {
                    //add a bit of delay for better rendering
                    showGeoJson(JSON.parse(field.shape), false);
                }
            }
        }
    }


    function handleFileCardClose(refresh) {
        if ((refresh) && (fieldid)) getFieldFiles(session, props.fieldid, setFiles, "json");
        setShowFileCard(false);
    }




    function resizeBoundingBox(bounds) {
        var point1 = turf.point([bounds[0], bounds[1]]);
        var point2 = turf.point([bounds[2], bounds[3]]);
        var bearing = turf.bearing(point1, point2);
        var distance = turf.distance(point1, point2);
        var p1 = turf.destination(point1, -0.2 * distance, bearing);
        var p2 = turf.destination(point2, 0.2 * distance, bearing);
        var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
        return box;
    }


    function applyFeatureCollection(collection) {
        localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new Event("featureCollectionChanged"));
    }

    function applyBoundingBox(bounds) {
        localStorage.setItem('boundingBox', JSON.stringify(bounds));
        dispatchEvent(new CustomEvent('boundingBoxChanged', { detail: bounds })); //cool can pass arguments in event this way
    }



    function drawField() {
        let collection = {
            "type": "FeatureCollection",
            features: []
        }
        console.log('shape');
        console.log(shape);

        applyFeatureCollection(collection); //empty collection - remove any features from map
        if (shape)
            if (shape.type == "Feature") {
                console.log('1');
                collection.features.push(JSON.parse(shape));
            }
            else {
                console.log('2');
                collection = JSON.parse(shape);
            }

        console.log('call edit collection');
        console.log(collection);
        localStorage.setItem('drawingFeature', JSON.stringify(collection));

        dispatchEvent(new Event("enterEditMode"));
        setHidden('hidden');

    }








        function changeFilter(newfilter) {
            

            switch (newfilter) { //filterName is fresh value
                case "days" : filterName="days"; break;
                case "ph" :   filterName="Ocena_ph"; break;
                case "p2o5" : filterName="Ocena_p2o5"; break;
                case "k2o" : filterName="Ocena_k2o"; break;
                case "mg" : filterName="Ocena_mg"; break;
            };
            setFilter(filterName);
            if (newfilter=="days") showGeoJson(JSON.parse(field.shape), false);
            if (newfilter!="days")
                {
                    if (results) { showGeoJson(results, false); }
                        else 
                        {
                            console.log('alert');
                            showAlert("green","Analizuję wyniki badań - to potrwa kilka sekund",2000);
                            setTimeout(()=>showGeoJson(JSON.parse(field.shape), false),300);
                        }
                }
        }




        function findResultFeatures(feature,name) {
            var matched=[];
            var fileid=feature.examined[0].fileid;
            var file=files.filter(f=> f.id==fileid)[0];
            if (file.jsondata)
                {
                    var results=JSON.parse(file.jsondata);
                    if (results.features) {
                        results.features.forEach(element => {
                            var intersection = turf.intersect(feature, element); 
                            if (intersection)       
                                {
                                    element.properties.value=6-element.properties[name]; /////////////////
                                    matched.push(element);
                                }
                            
                        });
                    }
                }
            
        return matched;        
        }





        function getExamTime(examined) {
            examined.sort((a, b) => b.timestamp - a.timestamp); //sort descending
            var now=Date.now();
            var datediff=(now-examined[0].timestamp)/(24*3600*1000);
            console.log(datediff/365);
            return datediff;
    
        }
    


        function applyFilterColors(collection) {
            var results=[];
            var result=[];
            if (!Array.isArray(collection.features)) return;
            collection.features.forEach(feature => {
                if (feature.examined)
                 { 
                    switch (filterName) { //filterName is fresh value
                    case "days" : feature.properties.value=1+Math.floor(getExamTime(feature.examined)/(365)); break;
                    default : result=findResultFeatures(feature,filterName); break;
                    }
                    
                    results.push(...result);
                }
                 else feature.properties.value=0;   
            });
            
            collection.features.push(...results);
            if (filterName!="days") setResults(collection);
            return collection;
        }


        function applyQuickFilterColors(collection) {
            console.log('filtername');
            console.log(filterName);
            if (!Array.isArray(collection.features)) return;
            collection.features.forEach(feature => {
                    if (filterName!="days") { feature.properties.value=6-feature.properties[filterName]; } //////////
                        else {if (feature.examined) { feature.properties.value=1+Math.floor(getExamTime(feature.examined)/(365)); } 
                                else {feature.properties.value=0;}
                    }
            });
            return collection;
        }



    
        function showGeoJson(feature, refresh) {
        if (!feature) return;

        let collection;
        try {
            if (feature.type == "FeatureCollection") {
                collection = feature;
            }
            else if (feature.type == "Feature") {
                collection = {
                    "type": "FeatureCollection",
                    features: [feature]
                }
            }
        } catch { console.log('unknown feature type'); }

        if (collection.features.length == 0) {
            console.log('No features to display');
            return;
        }

        //console.log('Featrue Collection ready to display');
        //console.log(collection);
        setFeaturesCount(collection.features.length);
        //set value based on examined.timestamp

        
        if (results) applyQuickFilterColors(collection); 
            else {applyFilterColors(collection);}
        
        //console.log('Featrue Collection with colors');
        //console.log(collection);

        let area = turf.area(feature) / 10000.0;
        let bounds = turf.bbox(feature);
        let center = turf.center(feature);
        let resizedbounds = resizeBoundingBox(bounds);

        //grid(resizedbounds, collection);
        //gridtest(resizedbounds, collection);

        applyFeatureCollection(collection);
        applyBoundingBox(resizedbounds);

        setArea(area.toFixed(2));
        setBBox(JSON.stringify(bounds));
        setCenter(JSON.stringify(center));
        setShape(JSON.stringify(feature));
        
        //for MapboxPreviewProps
        setPreviewFeature(collection);
        setPreviewBBox(resizeBoundingBox(bounds));
        

        if (refresh) setShowPreview(true);
    }

    
    



    function cancelClose() {
        console.log("close on send : " + closeOnSend);

        if (clearOnExit) {
            applyFeatureCollection(emptyGeoJson);
        }
        localStorage.removeItem('files');
        localStorage.removeItem('field');
        props.close(refreshAfterExit);
    }


    function handleDataResponse(data) {
        console.log("Handle response");
        console.log(data);
        setUploaded(true);
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);
            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }




    function handleRemoveField() {

        console.log("handling remove");
        console.log("close on send " + closeOnSend);
        setCloseOnSend(true);
        setRefreshAfterExit(true);
        setShowConfirmDelete(false);
        delay(2000);
        console.log("after delay ");
        console.log("close on send" + closeOnSend);
        var bodydata = JSON.stringify({ action: "remove", session: session, owner: ownerid, id: fieldid });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/fields.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then( res => res.json())
            .then(data => handleDataResponse(data))
    }


    function delay(miliseconds) {
        return new Promise(res => setTimeout(res, miliseconds));
    }


    ///////////////////////////////////////////////////////////////////////////

    function collectFeatures(feature) {
        console.log('collect');
        console.log(feature);
        if (!feature) return;
        collection.features.push(feature);
        console.log("expected " + expectFeatures + " got " + collection.features.length)
        if ((expectFeatures > 0) && (collection.features.length == expectFeatures))
            collectShowImported();
    }

    function collectShowImported() {
        console.log('import');
        console.log(collection);
        if (collection.features.length==0)  { console.log("parsing shape"); setImportedFeatures(JSON.parse(shape)); }
        else 
        { setImportedFeatures(collection); }
        showGeoJson(collection, false);
        setShowImportCard(true);
        
        //collection = emptyGeoJson;
    }


    function finishImport(selected) {
        console.log('finished');
        
        /*
        collection=selected;
        showGeoJson(collection, true);
        collection = emptyGeoJson;
        setShowImportCard(false);
        */    
        
        setShowImportCard(false);
        setFeaturesToSplit(selected);
        collectionToSplit = selected;
        //setTimeout(splitField, 50);
        
        selected.ownerid=ownerid;
        selected.crops=crops;

        localStorage.setItem('featuresToSplit', JSON.stringify(selected));

        
        splitField();
    }




    //////////////////////////////////////////////////////////////////////////////////////////////

    function handleSplitDataResponse(data) {
        console.log("handle Split Data Response");
        console.log(data)
        setUploaded(true);
        if (data.status === "ok") {
            showAlert("green", data.message, 1000);

        }

        if (data.status !== "ok") {
            showAlert("red", data.message, 1000);
        }
        setTimeout(splitField, 100);
    }



    function handleSplitField() {
        setShowConfirmSplit(false);
        //collectionToSplit = JSON.parse(localStorage.getItem("featureCollection"));
        setTimeout(splitField, 50);
    }




    function splitField() {
        
        try
        {
        collectionToSplit = JSON.parse(localStorage.getItem("featuresToSplit"));
        if (!collectionToSplit) return;
        if (collectionToSplit.features) console.log("split queue lenght " + collectionToSplit.features.length);
        if (collectionToSplit.features.length == 0) {
            console.log("Split finish");
            localStorage.removeItem("featuresToSplit");
            collectionToSplit = null;
            return;
        }

        } catch { console.log("catch"); };


        let single = emptyGeoJson;
        try
        {
            single.features.pop();
        }
        catch { /*empty*/}
        single.features.push(collectionToSplit.features.shift());
        localStorage.setItem('featuresToSplit', JSON.stringify(collectionToSplit));

        console.log('single feature');
        console.log(single);
        applyFeatureCollection(single);
        setImage(null);


        let fieldid = 0;

        let area = turf.area(single) / 10000.0;
        area=area.toFixed(2);
        setArea(area);

        let bounds = turf.bbox(single);
        var bbox= JSON.stringify(bounds);
        
        let center = turf.center(single);
        center=JSON.stringify(center);
        
        let shape= JSON.stringify(single);
        let splitname = single.features[0].properties.name.trim();
        
        setName(splitname);
        let ownerid=collectionToSplit.ownerid;
        let crops=collectionToSplit.crops;
        setCrops(crops);

        console.log("preparing split field data")
        console.log("field splitname " + splitname);
        let bodyData = { action: "create", session: session, name: splitname, area: area, crops: crops, description: description, owner: ownerid, id: fieldid, bbox: bbox, center: center, tested: JSON.stringify(tested), shape: shape};
        
        localStorage.setItem('splitBody', JSON.stringify(bodyData));

        //for MapboxPreviewProps
        setPreviewFeature(single);
        setPreviewBBox(resizeBoundingBox(bounds));

        console.log("Requesting new preview - waiting for image")
        
        //setUploaded(false);
        //setShowPreview(true);
        
        
        
        setTimeout(() => {
            setShowPreview(true);
            setUploaded(false);
        }, 300)
        
    }




    function sendPreparedBodyData() {
        console.log("SendPreparedBodyData");

        let splitBody = JSON.parse(localStorage.getItem('splitBody'));
        splitBody.image = localStorage.getItem("previewImage");

        let bodyString= JSON.stringify(splitBody);
        //console.log("Sending - bodyString is :");
        //console.log(bodyString);

        fetch('https://agro-online.pl/cgi-bin/fields.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodyString,
            mode: 'cors'
        })
            /*
            .then(res => console.log(res))
            .then(data => console.log(data))
            */
            .then(res => res.json())
            .then(data => handleSplitDataResponse(data))
            
    }



    ////////////////////////////////////////////////////////////////////////////////////


    function handleGroupFieldsStart() {
        setShowConfirmGroup(false);
        showAlert("green", "Grupowanie pól zostało rozpoczęte", 2000);
        setTimeout(handleGroupFields, 500);
    }

    function finishFieldGroup(shapes) {
        showGeoJson(shapes);
        //showAlert("green","Grupowanie pól zostało zakończone (z początkowej ilości " + startcount + " pozostało " + shapes.features.length + " pól)",3000);
        showAlert("green", "Grupowanie pól zostało zakończone", 3000);
    }


    function handleGroupFields() {
        let shapes = JSON.parse(shape);

        let startcount = shapes.features.length;
        console.log(shapes);
        let j = 0, i = 0, pas = 1;
        let delta;

        do {
            delta = shapes.features.length;
            console.log("pass " + (pas++));
            j = 0;
            while (j < shapes.features.length) {
                i = j + 1;
                while (i < shapes.features.length) {
                    let intersect = turf.union(shapes.features[i], shapes.features[j]);
                    if (intersect.geometry.type == "Polygon") {
                        //console.log("union of " + i +" and " + j);
                        shapes.features.splice(i, 1, intersect);
                        shapes.features.splice(j, 1);
                    }
                    else {
                        i++;
                    }
                }
                j += 1;
            }
        } while (delta != shapes.features.length);
        console.log('finished passes' + pas);
        showGeoJson(shapes);
        showAlert("green", "Grupowanie pól zostało zakończone (z początkowej ilości " + startcount + " pozostało " + shapes.features.length + " pól)", 3000);
    }


    function handleAddFieldFromGeoportal(data) {
        setShowGeoportalQuery(false);

        if (!data) return;
        data = data.replaceAll(",", " "); //remove comas
        let ewids = data.split(" ");
        let counter = 0;
        collection = emptyGeoJson;

        ewids.forEach(id => {
            if (id.length > 8) {
                geoportalGetParcelPolygon(id, collectFeatures);
                counter++;
            }
        })
        expectFeatures = counter;
    }




    function handleSubmitField(e) {
        e.preventDefault();
        var bodydata = JSON.stringify({ action: action, session: session, name: name, area: area, crops: crops, description: description, owner: ownerid, id: fieldid, bbox: bbox, center: center, tested: JSON.stringify(tested), shape: shape, image: image });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/fields.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }


    function onSelectChange(e) {
        console.log(e);
        setOwnerId(e);
        let client = clients.filter(c => c.id == e);
        if (client.length > 0) setClientName(client[0].name);
        console.log(clientName);
    }


    function descriptionChange(e) {
        setDescription(e.target.value);
    }

    function ClientSelector() {
        console.log(clients);
        return (
            clients.map((client) => (
                <Option value={client.id}>{client.name}</Option>
            ))
        );
    }



    var shpwrite = require("shp-write-update");

    function download_default() {
        var options = {
            folder: 'granice',
            types: {
                point: 'points',
                polygon: 'granice',
                polyline: 'lines'
            },
            file: 'granice'

        }
        // a GeoJSON bridge for features
        let parsed = JSON.parse(shape);
        console.log('downloading shape');
        console.log(parsed);
        shpwrite.download(parsed, options);
    }


    function download_alt() {

        let blob = new Blob([JSON.stringify(shape, null, 2)], {
            type: "application/json",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'granica.shp';
        a.click();
    }

    


    function download_csv(data) {
        let csv="";
        let counter=1;
        if (!shape) return;
        let fc=JSON.parse(shape);
        fc.features.forEach(feature=> {
            let coords=feature.geometry.coordinates[0]; //maybe  should display more than 1
            csv+="feature " + counter +",geometry" +"\r\n";
            csv+="longitude,latitude\r\n";
            coords.forEach(point => {
                csv+=point[0]+"," + point[1] +"\r\n";
            })
            counter++;
        })
        console.log(csv);

        let blob = new Blob([csv],{ type: 'text/csv' });
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name+'.csv';
        alink.click();
        window.URL.revokeObjectURL(fileURL);
    }


    function field_count(num) {
        let result;
        switch (num) {
            case 2: result = "2 pola"; break;
            case 3: result = "3 pola"; break;
            case 4: result = "4 pola"; break;
            default: result = num.toString() + " pól"
        }
        return result;
    }





    return (
        <>
            <Card color="white" className={`absolute w-full max-w-[20rem] h-auto ${cardPosition}   overflow-y-auto overflow-x-hidden mx-auto py-2 shrink ${hidden}`} shadow={false}>

                <div className="flex items-center justify-between gap-2 w-auto mt-2">
                    <Typography color="gray" className="mt-0 pl-12 font-normal mx-auto">
                        Karta informacyjna pola
                    </Typography>
                    <IconButton variant='text' className='top-[-0.5rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <form className="mt-4 mb-2 mx-auto w-[20rem] sm:w-80 items-center justify-center px-6 gap-3" onSubmit={handleSubmitField}>
                    <div className="mb-2 flex flex-col gap-3 w-auto items-start justify-center ">

                        {clients.length > 0 &&
                            <Select label="Wybierz właściciela pola" className={ownerid>0 ? "" : "border-blue-500 border-2"} value={ownerid > 0 ? ownerid.toString() : ''} onChange={onSelectChange}>
                                {
                                    clients.map((client) => (
                                        <Option key={client.id} value={client.id.toString()}>{client.name}</Option>
                                    ))
                                }
                            </Select>
                        }
                        <Input size="md" label="Nazwa pola" className="shadow-md" value={name} onChange={e => setName(e.target.value)} />

                        <Input size="md" label="Płodozmian" className="shadow-md" value={crops} onChange={e => setCrops(e.target.value)} />

                        <Datepicker i18n={"pl"} primaryColor="teal" asSingle={true} useRange={false} value={tested} onChange={setTested} inputClassName="w-full border rounded-md shadow-md py-2.5 px-2 border-gray-400 text-gray-900 text-normal text-sm focus:border-blue-500 focus:outline-0 focus:ring-0" placeholder="Data ostatniego badania" ></Datepicker>

                        <div className="hidden lg:block w-full">
                            <Textarea label="Dodatkowy opis" value={description} onChange={descriptionChange} />
                        </div>

                        <div className='w-full items-center justify-center inline-flex' >
                            <Typography variant="small" label="Areał" className="text-center" >Pole powierzchni : {area} Ha</Typography>
                            {(featuresCount > 1) && <Typography variant="small" label="Obszary" className="text-center ml-1" >({field_count(featuresCount)})</Typography>}

                        </div>

                        {showPreview && <div className="mx-auto w-[200px] h-[160px] rounded-lg" ><MapboxPreviewProps className="rounded-lg" bbox={previewBBox} features={previewFeature}/></div>}
                        {(!showPreview && image) && <div className="mx-auto w-[200px] h-[160px] rounded-lg" ><img src={image} className='rounded-lg'></img></div>}



                        <div className='flex flex-wrap items-center gap-2 mx-auto my-0'>


                            <Tooltip content="Narysuj obrys pola">
                                <IconButton variant="filled" color="green" size="md" className='w-32 h-32' onClick={() => drawField()} >
                                    <PencilIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            {clients.length>0 &&

                            <ShapeUnziper className="ml-0 mr-0" iconOnly={true} geoJson={collectFeatures} finished={collectShowImported} disabled={ownerid>0 ? false : true}/>
                            }        
                            
                            {clients.length>0 &&
                            <Tooltip content="Edytuj listę pól">
                                <IconButton variant="filled" color="green" size="md" className='w-32 h-32' onClick={collectShowImported} >
                                    <PencilSquareIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>
                            }        
                            
                            <Tooltip content="Dodaj pole z ewidencji gruntów">
                                <IconButton variant="filled" color="green" size="md" className='w-32 h-32' onClick={() => setShowGeoportalQuery(true)} >
                                    <MapIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip content="Grupuj przylegające pola">
                                <IconButton variant="filled" color="teal" size="md" className='w-32 h-32' onClick={() => setShowConfirmGroup(true)} disabled={featuresCount < 2} >
                                    <RectangleGroupIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            </div>
                            <div className='flex flex-wrap items-center gap-2 justify-between mx-auto mb-4'>
                            <Tooltip content="Pobierz obrys pola (*.shp)">
                                <IconButton variant="filled" color="blue" size="md" className='w-32 h-32' onClick={() => download_default()} disabled={!shape} >
                                    <InboxArrowDownIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip content="Pobierz obrys pola (*.csv)">
                                <IconButton variant="filled" color="indigo" size="md" className='w-32 h-32' onClick={() => download_csv()} disabled={!shape} >
                                    <InboxArrowDownIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip content="Sprawdź wyniki badań">
                                <IconButton variant="filled" color="orange" size="md" className='w-32 h-32' onClick={() => setShowDoubleCard(true)} >
                                    <ArrowTopRightOnSquareIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>

                            {clients.length>0 &&
                            <Tooltip content="Przetwarzanie wyników badań">
                                <IconButton variant="filled" color="amber" size="md" className='w-32 h-32' onClick={() => setShowResultsProcessingCard(true)} >
                                    <BeakerIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>
                            }

                            <Badge className={files.length == 0 ? "hidden" : ""} content={files.length}>
                                <Tooltip content="Pokaż pliki powiązane z tym polem">
                                    <IconButton variant="filled" color="purple" size="md" className='w-32 h-32' onClick={() => setShowFileCard(true)} disabled={fieldid == 0}>
                                        <PaperClipIcon className='w-5 h-5' color="white" />
                                    </IconButton>

                                </Tooltip>
                            </Badge>





                        </div>

                        <div className='mx-auto flex flex-row justify-between w-full'>
                            <Tooltip content="Usuń pole">
                                <IconButton variant="filled" color="red" size="md" className='w-32 h-32 ml-0 mr-auto' onClick={() => setShowConfirmDelete(true)} disabled={fieldid == 0} >
                                    <TrashIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>


                            <Button className="items-center w-[8rem] ml-auto mr-0 justify-center bg-gradient-to-br from-teal-500 to-cyan-500" data-refresh={true} fullWidth type="submit">
                                Zapisz
                            </Button>
                        </div>




                    </div>

                </form>
            </Card>
    
            <div className='absolute top-2.5 right-2.5 flex flex-col'> 
            <Tooltip content="Data ostatniego badania">
              <Button variant="filled" className='rounded-b-none bg-gray-100 hover:shadow-none hover:bg-blue-200 p-2 items-center' onFocus={true} onClick={() => changeFilter("days")} >
                <CalendarDaysIcon className='w-4 h-4 mx-auto' color="black" />
              </Button>
            </Tooltip>
            <Tooltip content="Ocena PH">
              <Button variant="filled" className='px-1 py-2 rounded-t-none rounded-b-none bg-gray-100 hover:shadow-none hover:bg-blue-200 border' onClick={() => changeFilter("ph")} >
              <Typography variant="small" color="black" className="text-[9px] caption-normal">PH</Typography>
              </Button>
            </Tooltip>
            <Tooltip content="Ocena Potasu">
              <Button variant="filled" className='px-1 py-2 rounded-t-none rounded-b-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={() => changeFilter("k2o")} >
                <Typography variant="small" color="black" className="text-[9px] caption-normal">Potas</Typography>
              </Button>
            </Tooltip>
            <Tooltip content="Ocena Magnezu">
              <Button variant="filled" className='px-1 py-2 rounded-t-none rounded-b-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={() => changeFilter("mg")} >
                <Typography variant="small" color="black" className="text-[9px] caption-normal">Magnez</Typography>
              </Button>
            </Tooltip>
            <Tooltip content="Ocena Fosforu">
              <Button variant="filled" className='px-1 py-2 rounded-t-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={() => changeFilter("p2o5")} >
                <Typography variant="small" color="black" className="text-[9px] caption-normal">Fosfor</Typography>
              </Button>
            </Tooltip>
            </div>


        {filter=="days" && 
            <div className='absolute top-[95vh] left-[16vw] h-10 flex flex-row'> 
              <Button variant="filled" className='rounded-r-none bg-maplegend-none hover:shadow-none p-1 items-center w-12'>
              <Typography variant="small" color="white" className="text-[11px] normal-case wrap justify-center">Brak badań</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-min hover:shadow-none w-12' >
              <Typography variant="small" color="black" className="text-xs caption-normal">1-12</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step1 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-xs caption-normal">12-24</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step2 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-xs caption-normal">24-36</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step3 hover:shadow-none w-12'>
                <Typography variant="small" color="black" className="text-xs caption-normal">36-48</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step4 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-xs caption-normal">48-60</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-l-none bg-maplegend-max hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-xs caption-normal">60+</Typography>
              </Button>

            </div>
        }                
        {filter !="days" && 
            <div className='absolute top-[95vh] left-[16vw] h-10 flex flex-row'> 
              <Button variant="filled" className='px-1 py-2 rounded-r-none bg-maplegend-min hover:shadow-none w-12' >
              <Typography variant="small" color="black" className="text-[10px] caption-normal">b. wysoka</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step1 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-[10px] caption-normal">wysoka</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step2 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-[10px] caption-normal">średnia</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step3 hover:shadow-none w-12'>
                <Typography variant="small" color="black" className="text-[10px] caption-normal">niska</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-none bg-maplegend-step4 hover:shadow-none w-12' >
                <Typography variant="small" color="black" className="text-[10px] caption-normal">b. niska</Typography>
              </Button>
              <Button variant="filled" className='px-1 py-2 rounded-l-none bg-maplegend-none hover:shadow-none w-12' >
              <Typography variant="small" color="white" className="text-[10px] caption-normal wrap">???</Typography>
              </Button>

            </div>
        }                

            {showFileCard && <FileUploadCard close={handleFileCardClose} fieldid={fieldid} clientid={ownerid}/>}
            {showDoubleCard && <DoubleFieldCard close={() => setShowDoubleCard(false)} fieldid={fieldid} />}
            {showResultsProcessingCard && <ResultsProcessingCard close={() => setShowResultsProcessingCard(false)} fieldid={fieldid} field={field}/>}
            {showImportCard && <FieldImportCard close={() => setShowImportCard(false)} imported={importedFeatures} onFinish={finishImport}/>}

            <ConfirmDialog open={showConfirmDelete} message="Czy na pewno chcesz usunąć to pole" onCancel={() => setShowConfirmDelete(false)} onConfirm={handleRemoveField} />
            <ConfirmDialog open={showConfirmGroup} message="Czy na pewno chcesz połączyc w grupę przylegajace do siebie pola? (to może chwilę potrwać)" onCancel={() => setShowConfirmGroup(false)} onConfirm={handleGroupFieldsStart} />
            <ConfirmDialog open={showConfirmSplit} message="Czy na pewno chcesz utworzyć osobne pola na podstawie wczytanych obszarów. (Upewnij się, że prawidłowo ustawione zostały dane właściciela i wstępna nazwa pola)" onCancel={() => setShowConfirmSplit(false)} onConfirm={handleSplitField} />
            <QueryDataDialog open={showGeoportalQuery} message="Wprowaź identyfikatory działek (możesz podać kilka identyfikatorów oddzielonych spacją lub przecinkiem)" onCancel={() => setShowGeoportalQuery(false)} onConfirm={handleAddFieldFromGeoportal} />
        </>
    );


}

