import React, { useState } from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    IconButton,
    Button,
    Tooltip,
    ListItem,
    ListItemPrefix,
    Typography,
    Progress,

} from "@material-tailwind/react";
import {
    PencilSquareIcon,
    UserIcon,
    FolderPlusIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";


import OfferCard from './OfferCard';
import OrderAdminCard from './OrderAdminCard';
import { getClients, getOffers, getOrders } from './AdminTools';
import Datepicker from "react-tailwindcss-datepicker"; 
import { json } from 'react-router-dom';
import { getFields, getFieldsInfo } from './AdminTools';

export default function OrdersList(props) {
    const [offerid, setOfferId] = useState(0);
    const [orderid, setOrderId] = useState(0);
    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
    const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('');
    const [showOrderCard, setShowOrderCard] = useState(false);


    React.useEffect(() => {
        console.log("Orders ")
        if (offers.length==0) getOffers(session,setOffers);
        if (orders.length==0) getOrders(session,setOrders);
        if (clients.length==0) getClients(session,setClients);
        if (fields.length==0) {
                getFields(session,setFields);
                getFieldsInfo(session,() => void 0);    
            }   
    }, [offers,clients,fields,orders]);



    const handleShowOrderCard = (e) => {
        const id = e.currentTarget.getAttribute("data-orderid");
        setOrderId(id);
        setShowOrderCard(showOrderCard === true ? false : true);
    };


    function cancelClose() {
        props.Close();
    }

    
    function showHideCard(refresh) {
        setShowOrderCard();
        console.log('refresh ? ' + refresh);
        if (refresh == true) getOrders(session,setOrders);
    }

    

    return (
        <>
            <Card className={`absolute my-auto top-[1rem] max-h-[calc(100vh-2rem)] w-full lg:max-w-[calc(100vw-18rem)] max-w-[calc(100vw-7rem)] p-2 shadow-xl lg:left-[17rem] left-[6rem] z-1 overflow-y-auto ${showOrderCard==true ? 'opacity-75' : 'opacity-100'}`}>
                        <IconButton variant='text' className='top-2 absolute right-2 ml-auto' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-5 h-5' />
                       </IconButton>

                <CardHeader floated={false} shadow={false} className="rounded-none mr-16 mb-0">

                    <div className="mb-6 flex flex-row justify-between gap-4 md:items-center">
                        <div className="flex w-full gap-2 w-max mb-4">
                            <Typography variant="h6" color="blue-gray">
                                Zestawienie zleceń 
                            </Typography>
                            <Typography color="gray" className="mt-1 font-normal">
                                
                            </Typography>
                        </div>
                        <div className="flex w-full gap-2 w-max mb-4">
                            <Button className="flex items-center gap-3" size="sm" color="green" data-offerid={0} onClick={handleShowOrderCard}>
                                <FolderPlusIcon strokeWidth={2} className="h-4 w-4" /> Nowe zlecenie
                            </Button>
                        </div>

                    </div>
                </CardHeader>
                <CardBody className="overflow-auto px-0">
                    <table className="w-full table-auto text-left">
                        <thead>
                            <tr>
                                <th key="owner" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs">Nazwa Klienta</Typography>
                                </div>
                                </th>
                                <th key="field" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden md:block">Nazwa Pola</Typography>
                                </div>
                                </th>
                                <th key="area" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden md:block">Areał</Typography>
                                </div>
                                </th>
                                <th key="offer" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs">Zakres badań</Typography>
                                </div>
                                </th>
                                <th key="price" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 text-center md:text-md text-xs">Cena</Typography>
                                </div>
                                </th>
                                <th key="date" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 text-center md:text-md text-xs hidden md:block">Data</Typography>
                                </div>
                                </th>
                                <th key="status" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 text-center md:text-md text-xs">Status</Typography>
                                </div>
                                </th>

                                <th key="uwagi" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-center'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden lg:block">Uwagi</Typography>
                                </div>
                                </th>
                                <th key="edit" className="border-y w-32 border-blue-gray-100 bg-blue-gray-50/50 p-2 ">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders && orders.map(
                                (
                                    {
                                        id,
                                        fieldid,
                                        name,
                                        offerid,
                                        description,
                                        estimatedvalue,
                                        progress,
                                        status,
                                        proposaldate,
                                        finaldate,
                                        finalvalue,
                                        fieldlist,
                                        
                                    },
                                    index,
                                ) => {
                                    const isLast = index === offers.length - 1;
                                    const classes = isLast ? "p-2" : "p-2";
                                    
                                    let field={};
                                    let client={}
                                    try
                                    {
                                    field = fields.filter(f=>f.id==fieldid)[0];
                                    client = clients.filter(c=>c.id==field.owner)[0];
                                    } catch {
                                        return;
                                    };
                                    


                                    var offer = offers.filter(o=>o.id==offerid)[0];    
                                    if (client==null)
                                        {
                                            client=useState({id:field.owner, name:'usuniety', company:'brak danych', firstname:'--', lastname:'--'});
                                        }    
                                    
                                    if (!field)
                                       {
                                            field={ id:fieldid, name:'---', area : 0 };
                                       }    
                                    if (offer==null) {
                                        offer={ id:0, name: 'nieznany' };
                                    }  
                                    
                                    let parsedlist=JSON.parse(fieldlist);

                                    let area_sum=0;
                                    if (parsedlist) {
                                    fields.forEach(field => {
                                            
                                            if (parsedlist.includes(field.id))
                                                { area_sum += Number(field.area); };
                                        });
                                    area_sum=area_sum.toFixed(2);  
                                    }

                                    let jsondates={}; 
                                    let dateString="";
                                    try
                                    {
                                        jsondates.proposal=JSON.parse(proposaldate);
                                        jsondates.final=JSON.parse(finaldate);
                                        
                                        if (jsondates.final.startDate) { jsondates.startDate=jsondates.final.startDate; }
                                            else { jsondates.startDate=jsondates.proposal.startDate; }
                                        if (jsondates.final.endDate) { jsondates.endDate=jsondates.final.endDate; }
                                            else { jsondates.endDate=jsondates.proposal.endDate; }

                                        if (jsondates.startDate==jsondates.endDate) {
                                        dateString=jsondates.startDate.toString(); 
                                        }
                                        else
                                        {
                                        dateString=jsondates.startDate.toString() + "~" + jsondates.endDate.toString(); 
                                        }
                                    } 
                                    catch { console.log('unexpected dates - proposal :' + proposaldate +", final : " + finaldate + ", jsondate :" + jsondates); dateString="nieokreślono"; };

                                    let listprice = estimatedvalue;
                                    if (finalvalue>0) listprice=finalvalue;   

                                    return (
                                        <tr key={id} className='even:bg-blue-gray-50/50'>
                                            <td className={classes}>
                                                <div className="flex items-center gap-1">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {client.name}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal hidden md:block">
                                                    {name}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-bold hidden md:block">
                                                    {area_sum + " Ha"}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {offer.name}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-bold text-center">
                                                    {listprice}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal text-xs mx-auto text-center hidden md:block">
                                                    {dateString}
                                            </Typography>
                                            </td>
                                            
                                            <td className={classes}>
                                            <div className='flex flex-col items-center'>
                                            <Typography variant="small" color="blue-gray" className="font-normal text-xs mx-auto break-normal mb-1">
                                                    {status}
                                            </Typography>
                                            <Progress value={progress} size="md" className="w-32 mx-auto hidden sm:block"/>
                                            </div>
                                            </td>
                                            <td className={classes}>
                                            
                                            <Typography variant="small" color="blue-gray" className="font-normal hidden lg:block">
                                                    {description}
                                                </Typography>
                                            </td>

                                            <td className="p2 items-right flex">
                                                <Tooltip content="Edytuj zlecenie" >
                                                    <IconButton variant="gradient" size="sm" className="ml-auto mr-2 mt-1.5 " data-orderid={id} onClick={handleShowOrderCard}> 
                                                        <PencilSquareIcon className="h-5 w-5" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            ${showOrderCard === true ? <OrderAdminCard Close={showHideCard} id={orderid} /> : ''}
        </>
    );
}

