import React, { useState, useRef } from 'react';



import {
    Alert,
    Card,
    Input,
    Checkbox,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Tooltip,
    Select,
    Option,
    Textarea,

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    TrashIcon,
    BeakerIcon,
    ChatBubbleLeftIcon,
    EnvelopeIcon

} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Datepicker from "react-tailwindcss-datepicker";
import { getFields } from './AdminTools.jsx';
import { showAlert } from './Alert.jsx';

import { PdfPreview } from "./LabForm";

import SmsCard from './SmsCard.jsx';
import EmailCard from './EmailCard.jsx';
import FieldsCheckList from './FieldsCheckList;.jsx';

export default function OrderAdminCard(props) {

    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
    const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);

    const [clientFields, setClientFields] = useState();
    const [selectedFields, setSelectedFields] = useState();

    const [action, setAction] = useState('create');

    const [field, setField] = useState();
    const [offer, setOffer] = useState();
    const [order, setOrder] = useState();
    const [client, setClient] = useState();

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    const [area, setArea] = useState();
    const [offerPrice, setOfferPrice] = useState();

    const [estimatedValue, setEstimatedValue] = useState();
    const [finalValue, setFinalValue] = useState();

    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [proposaldate, setProposalDate] = useState({ startDate: null, endDate: null });
    const [finalDate, setFinalDate] = useState({ startDate: null, endDate: null });



    const [progress, setProgress] = useState(1);
    const [status, setStatus] = useState('Zlecenie zgłoszone do realizacji');
    const [message, setMessage] = useState('Twoje zlecenie w portalu Agro-Online zmieniło status');
    const [subject, setSubject] = useState('Twoje zlecenie zmieniło status');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [clientName, setClientName] = useState('');

    const [showSmsCard, setShowSmsCard] = useState(false);
    const [showEmailCard, setShowEmailCard] = useState(false);
    const [showPDF, setShowPDF] = useState(false);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);


    const [confirm, setConfirm] = useState(false);
    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);

    const [jobNumber, setJobNumber] = useState("");
    const [fieldMark, setFieldMark] = useState("");
    const [probesCount, setProbesCount] = useState(0);

    const [labData, setLabData] = useState({});

    const PROGRESS = [
        { id: 1, status: "Zlecenie otwarte do wyboru pól" },
        { id: 2, status: "Zlecenie zgłoszone do realizacji" },
        { id: 3, status: "Zlecenie przyjęte do wyceny" },
        { id: 5, status: "Zlecenie gotowe do akceptacji" },
        { id: 10, status: "Zlecenie zaakceptowane" },
        { id: 30, status: "Pobieranie próbek z pola" },
        { id: 35, status: "Pobrano część próbek" },
        { id: 40, status: "Zakończono pobieranie próbek" },
        { id: 45, status: "Przygotowywanie próbek do wysyłki" },
        { id: 50, status: "Próbki zostały wysłane do laboratorium" },
        { id: 60, status: "Oczekianie na dane z laboratorium" },
        { id: 80, status: "Opracowywanie danych z laboratorium" },
        { id: 100, status: "Zlecenie zakończone - dane dostępne" },
    ]




    React.useEffect(() => {
        console.log('props');
        console.log(props);
        let order = {};
        if (orders.length > 0) {
            let ordersarray = orders.filter(o => o.id == props.id);
            console.log('got array');
            
            console.log('got client fields');
            console.log(ordersarray.length);
            if (ordersarray.length == 1) {
                console.log('got one order');
                order = ordersarray[0];
                console.log(order);
                setOrder(order);
                setEstimatedValue(order.estimatedvalue);
                setDescription(order.description);
                setProgress(order.progress);

                var selected=JSON.parse(order.fieldlist);
                setSelectedFields(selected);

                if (order.properties) {
                    let properties = JSON.parse(order.properties)
                    setJobNumber(properties.jobnumber);
                    setProbesCount(properties.probes);
                    setFieldMark(properties.fieldmark);
                }

                if (order.finalvalue) setFinalValue(order.finalvalue);
            }
        }

        if (props.id > 0) {
            setAction('update');

            if (order.finaldate)
                setFinalDate(JSON.parse(order.finaldate));
            console.log('order proposal date');
            console.log(order.proposaldate);

            if (order.proposaldate) {
                let propdate = JSON.parse(order.proposaldate);
                setProposalDate(propdate);
                try {
                    setMinDate(propdate.startDate);
                    setMaxDate(propdate.endDate);
                } catch { console.log('error setting min max date') };
            }

            let offerarray = offers.filter(o => o.id == order.offerid);
            if (offerarray.length == 1) {
                let offer = offerarray[0];
                setOffer(offer);

            }

            let fieldarray = fields.filter(f => f.id == order.fieldid);
            console.log('field array');
            console.log(fieldarray);

            if (fieldarray.length == 1) {
                let field = fieldarray[0];
                if (field) setField(field);

                let clientarray = clients.filter(c => c.id == field.owner);
                console.log('client array');
                console.log(clientarray);

                if (clientarray.length == 1) {
                    let client = clientarray[0];
                    setClient(client);
                    setEmail(client.email);
                    setPhone(client.phone);
                    setClientName(client.name);
                    setField(field);
                    getFields(session,setClientFields,client.id);
                    //setFieldId(field.id);

                }

            }
        }
        else {
            if (offers.length > 0) {
                setOffer(offers[0]);
            }

        }

    }, []);


    React.useEffect(() => {
        
        if (!clientFields) return;
        console.log('Client fields before');
        console.log(clientFields);
        console.log('Selected Fields');
        console.log(selectedFields);

        let area_sum=0;
        clientFields.forEach(field => {
            if (selectedFields.includes(field.id)) { field.selected=true; area_sum+=Number(field.area); }
            else  { field.selected=false; }
        });
        setArea(area_sum);
        
        console.log('Client fields after');
        console.log(clientFields);

    }, [clientFields]);


    function getFieldArea(fid) {
        let field = fields.filter(f => f.id == fid)[0];
        if (field) { return field.area; }
        else { return 0; }
    }


    function cancelClose() {
        props.Close(refreshAfterExit);
    }


    function handleDataResponse(data) {

        console.log(data)
        //setUploaded(true);
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);
            if (closeOnSend) { props.close(refreshAfterExit); }
        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }


    function handleQuietResponse(data) {
        //setUploaded(true);
        if (data.status === "ok") {
            console.log(data)
        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }




    function deleteOrder() {

        let _orderid = 0;
        if (order) {
            _orderid = order.id;
        }
        let _fieldid = 0;
        if (field) {
            _fieldid = field.id;
        }

        let bodydata = JSON.stringify({ action: 'remove', session: session, id: _orderid, fieldid: _fieldid });
        console.log(bodydata);

        setConfirm(false);
        setCloseOnSend(true);

        fetch('https://agro-online.pl/cgi-bin/orders.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }


    function getSelectedFieldsIds() {
        var selectedarray = [];
        var selected=clientFields.filter((field) => field.selected===true);
        
        selected.forEach(element => {
            selectedarray.push(Number(element.id));
        });
        
        
        return selectedarray;
    }





    function handleSubmit(e) {
        e.preventDefault();
        let _orderid = 0;
        if (order) {
            _orderid = order.id;
        }

        let properties = {
            probes: probesCount,
            jobnumber: jobNumber,
            fieldmark: fieldMark
        }

        var selected=getSelectedFieldsIds();
        setSelectedFields(selected);


        let bodydata = JSON.stringify({
            action: action, session: session, id: _orderid, name: name, description: description, proposaldate: JSON.stringify(proposaldate), finaldate: JSON.stringify(finalDate), area:area,
            fieldid: field.id, offerid: offer.id, properties: JSON.stringify(properties), offerprice: offerPrice, finalvalue: finalValue, progress: progress, fieldlist: JSON.stringify(selected), status: status
        });
        setCloseOnSend(false);
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/orders.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))

        // mark field as probed or not  
        let probing="stop"
        if ((progress >= 30) && (progress < 40)) { probing="start"; }    
        
        if ((progress >= 30) && (progress <= 80)) {
        bodydata = JSON.stringify({ action: probing, session: session, id: field.id });
            console.log(bodydata);
            fetch('https://agro-online.pl/cgi-bin/fields.py', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: bodydata,
                mode: 'cors'
            })
                .then(res => res.json())
                .then(data => handleQuietResponse(data))
        }
    }



    function onSelectClientChange(e) {
        //setClientFields(fields.filter(f => f.owner == e))
        setClient(clients.filter(c => c.id == e)[0]);
        getFields(session, setFields, e);
    }


    function onSelectFieldChange(e) {
        console.log("field changed  " + e);
        setField(fields.filter(f => f.id == e)[0])

    }

    function generateLabForm() {
        let today = new Date().toLocaleDateString();
        let data = {
            client: client,
            field: field,
            date: today,
            analysis: offer.labname,
            jobnumber: jobNumber,
            fieldmark: fieldMark,
            probes: probesCount
        };
        setLabData(data);
        setShowPDF(true);
    }



    function onSelectOfferChange(e) {
        console.log(e);
        setOffer(offers.filter(off => off.id == e)[0]);
        let selected = offers.filter(off => off.id == e)[0];
        if (selected) setOfferPrice(selected.price);
    }

    function onSelectProgressChange(e) {
        console.log('progress is changed');
        console.log(e);
        setProgress(e);
        let parray = PROGRESS.filter(p => p.id == e);
        if (parray.length == 1) {
            setStatus(parray[0].status);
        }
    }


    function descriptionChange(e) {
        setDescription(e.target.value);
    }


    function handleDateChange(value) {
        console.log("final date :", value);
        setFinalDate(value);
    }

    function handleShowSmsCard() {
        setShowSmsCard(!showSmsCard);
    }

    function handleShowEmailCard() {
        setShowEmailCard(!showEmailCard);
    }

    function handleNotChange(value) {

        if ((proposaldate.endDate == null) && (proposaldate.endDate == null)) { setProposalDate(value); console.log("changing proposal date : ", value); }
        else { console.log("not changing date : ", value); }
    }


    return (
        
        <div className='absolute w-full h-full top-0 left-0 items-center shadow-md justify-cener flex overflow-y-auto'>
            <Card color="white" className="w-[28rem] mx-auto my-auto py-4 shadow-xl shrink max-h-[98vh] z-5 overflow-y-auto overflow-x-none" shadow={true}>

                <div className="flex flex-row justify-between gap-2 w-auto my-2 ml-6">
                    <Typography variant="h6" color="blue-gray" >
                        Karta zlecenia
                    </Typography>
                    <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>

                <form className="mt-2 mb-0 mx-auto w-[26rem] items-center justify-center px-2" onSubmit={handleSubmit}>
                    <div className="mb-2 flex flex-col gap-3 w-full items-center justify-center ">


                        <Select label="Klient" className="shadow-md" value={client ? client.id.toString() : ''} onChange={onSelectClientChange}>
                            {
                                clients.map((client) => (
                                    <Option key={client.id} value={client.id.toString()}>{client.name}</Option>
                                ))
                            }
                        </Select>
                         {/*   
                        <Select label="Pole" className="shadow-md" value={field ? field.id.toString() : ""} onChange={onSelectFieldChange}>
                            {
                                fields && fields.map((cf) => (
                                    <Option key={cf.id.toString()} value={cf.id.toString()}>{cf.name + " (" + cf.area + " Ha)"}</Option>
                                ))
                            }
                        </Select>
                        */}
                        
                        <div className='w-[24rem] h-[14rem]'>
                        <FieldsCheckList fields={clientFields} selected={selectedFields} />
                        </div>
                        
                        
                        <Select label="Zakres badania " className="shadow-md" value={offer ? offer.id.toString() : ''} onChange={onSelectOfferChange}>
                            {
                                offers.map((value) => (
                                    <Option key={value.id} value={value.id.toString()}>{value.name + " (" + value.price + "zł / Ha)"}</Option>
                                ))
                            }
                        </Select>




                        <Typography variant="small" color="blue-gray" className="relative mx-auto text-xs ml-0 mr-auto mb-0 top-1">
                            Sugerowany, przez klienta, zakres czasowy do wykonania zlecenia
                        </Typography>

                        <Datepicker i18n={"pl"} primaryColor="green" readOnly={true} value={proposaldate} onChange={handleNotChange} inputClassName="w-full border rounded-md shadow-md py-2 px-2 border-gray-400 text-gray-600 text-normal focus:border-blue-500 focus:outline-0 focus:ring-0" placeholder="Proponowana data realizacji" ></Datepicker>
                        <Typography variant="small" color="blue-gray" className="relative mx-auto text-xs ml-0 mr-auto mb-0 top-1">
                            Wybrana data wykonania zlecenia
                        </Typography>

                        <Datepicker i18n={"pl"} primaryColor="green" asSingle={false} value={finalDate} onChange={handleDateChange} inputClassName="w-full border rounded-md shadow-md py-2 px-2 border-gray-400 text-gray-600 text-normal focus:border-blue-500 focus:outline-0 focus:ring-0" placeholder="Proponowana data realizacji" ></Datepicker>

                        <div className='flex max-w-[16rem] mt-2'>
                            <Input size="md" label="Cena końcowa" className="shadow-md text-center" value={finalValue} onChange={e => setFinalValue(e.target.value)} />
                        </div>

                        <div className='flex w-full my-2'>
                            <Select label="Postęp realizacji zadania" className="shadow-md" value={order ? order.progress.toString() : ''} onChange={onSelectProgressChange}>
                                {
                                    PROGRESS.map((value) => (
                                        <Option key={value.id} value={value.id.toString()}>{value.status}</Option>
                                    ))
                                }
                            </Select>
                            <Tooltip content="Powiadom klienta SMS'em">
                                <IconButton variant='gradient' className='ml-4 px-3' color="blue" data-refresh={false} onClick={handleShowSmsCard} >
                                    <ChatBubbleLeftIcon className='w-6 h-6' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip content="Powiadom klienta Email'em">
                                <IconButton variant='gradient' className='ml-3 px-3' color="blue" data-refresh={false} onClick={handleShowEmailCard} >
                                    <EnvelopeIcon className='w-6 h-6' />
                                </IconButton>
                            </Tooltip>

                        </div>

                        {progress >= 35 &&
                            <>
                                <div className='flex flex-row justify-between w-full gap-[2rem] max-w-[28rem] '>
                                    <Input size="md" label="Ilość pobranych próbek" className="shadow-md text-center flex" value={probesCount} onChange={e => setProbesCount(e.target.value)} />
                                    <Input size="md" label="Numer zlecenia" className="shadow-md text-center flex" value={jobNumber} onChange={e => setJobNumber(e.target.value)} />
                                </div>
                                <div className='flex flex-row justify-between w-full gap-[2rem] max-w-[28rem] '>
                                    <div className="w-[12rem]">
                                        <Input size="md" label="Oznaczenie pola" className="shadow-md text-center flex" value={fieldMark} onChange={e => setFieldMark(e.target.value)} />
                                    </div>
                                    <div className="w-[12rem] flex items-center justify-center">
                                        <Tooltip content="Wygeneruj zlecenie dla Laboratorium">
                                            <IconButton variant='gradient' className='px-3' color="blue" data-refresh={false} onClick={generateLabForm} >
                                                <BeakerIcon className='w-6 h-6' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </>
                        }

                        
                        <Textarea  label="Uwagi" value={description} className="shadow-md mt-2 max-h-[6rem]" onChange={descriptionChange} />
                        

                        <div className='flex flex-row justify-between w-full mt-4 mb-0' >
                            <IconButton variant='gradient' className='ml-0' color="red" data-refresh={false} onClick={() => setConfirm(true)} >
                                <TrashIcon className='w-6 h-6' />
                            </IconButton>
                            <Button className="items-center  mr-0 w-40 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 " data-refresh={true} fullWidth type="submit">
                                Zapisz
                            </Button>
                        </div>
                    </div>

                </form>
            </Card>

            {showSmsCard && <SmsCard close={handleShowSmsCard} name={clientName} phone={phone} message={message} />}
            {showEmailCard && <EmailCard close={handleShowEmailCard} name={clientName} email={email} subject={subject} message={message} />}
            {showPDF && <PdfPreview close={() => setShowPDF(false)} props={labData} />}

            <Dialog open={confirm} size={"xs"} handler={setConfirm}>
                <DialogHeader>Jesteś pewien ?</DialogHeader>
                <DialogBody divider>
                    Czy na pewno chcesz usunąć to zlecenie
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={() => setConfirm(false)} className="mr-8">
                        <span>Nie</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => deleteOrder()}>
                        <span>Tak</span>
                    </Button>
                </DialogFooter>
            </Dialog>


        </div>
    );


}

