import React, { useEffect, useState } from 'react';



import {
    Checkbox,
    Tooltip,

} from "@material-tailwind/react";


import { HorizontalSmallFieldCard } from './ViewFieldCard.jsx';
import * as turf from '@turf/turf'


export default function FieldsCheckList(props) {
        const [fields, setFields] = useState(props.fields || []);
        const [selected, setSelected] = useState(props.selected);
        const [featureCollection, setFeatureCollection] = useState(JSON.parse(localStorage.getItem("featureCollection")));

        useEffect(() => {
            setSelected(props.selected);
        },[props.selected]);

        useEffect(() => {
            if (props.fields) setFields(props.fields);
        },[props.fields]);

        console.log('selected looks like');
        console.log(selected);
        console.log('fields looks like');
        console.log(fields);
        console.log('featureCollection looks like');
        console.log(featureCollection);

        
        function FieldItem(props) {
            const [checked,setChecked] = useState(selected.includes(props.field.id));
            var field=props.field;
            var index=props.index;

            let test = "Brak daty ostatniego badania";
            if (field.tested) {
                let lasttest = JSON.parse(field.tested);
                if (lasttest.startDate) test = "Ostatnie badanie dnia " + lasttest.startDate;
            }

            function resizeBoundingBox(bounds) {
                var point1 = turf.point([bounds[0], bounds[1]]);
                var point2 = turf.point([bounds[2], bounds[3]]);
                var bearing = turf.bearing(point1, point2);
                var distance = turf.distance(point1, point2);
                var p1 = turf.destination(point1, -0.2 * distance, bearing);
                var p2 = turf.destination(point2, 0.2 * distance, bearing);
                var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
                return box;
            }

            buildFeatureCollection();

            function buildFeatureCollection() {
                let newfc = {
                    type: "FeatureCollection",
                    features: []
                  }
                fields.forEach(field => {
                    let fieldshape=JSON.parse(field.shape);
                    if (field.selected==true) {
                    fieldshape.features.forEach(feature => {
                        feature.properties.value=1;
                        newfc.features.push(feature);
                    });
                    }
                });
                console.log("New FC");
                console.log(newfc);
                if (newfc.features.length==0) 
                {
                    fields.forEach(field => {
                        let fieldshape=JSON.parse(field.shape);
                        fieldshape.features.forEach(feature => {
                            feature.properties.value=0;
                            newfc.features.push(feature);
                        });
                        }
                    );
                }
                
                let bounds = turf.bbox(newfc);
                let resizedbounds = resizeBoundingBox(bounds);
                applyFeatureCollection(newfc);
                applyBoundingBox(resizedbounds);
            }


            function applyFeatureCollection(collection) {
                localStorage.setItem('featureCollection', JSON.stringify(collection));
                dispatchEvent(new Event("featureCollectionChanged"));
            }
        

            function applyBoundingBox(bounds) {
                localStorage.setItem('boundingBox', JSON.stringify(bounds));
                dispatchEvent(new CustomEvent('boundingBoxChanged', { detail: bounds })); //cool can pass arguments in event this way
            }
        


            function changeFieldsSelected(id,select) {
                if (!fields) return;
                var field=props.fields.filter((field) => field.id == id)[0];
                props.field.selected=select;
                buildFeatureCollection();    
            }
        


            function changeChecked(e)
                {
                    
                    var state = e.target.checked;
                    var id = Number(e.target.attributes.value.value);
                    console.log("state " + state + " checked " + checked + " id " + id);
                    
                    if (checked===true) setChecked(false)
                        else setChecked(true);
                    changeFieldsSelected(id,!checked);
                }


            return (
                <Tooltip content={test}>
                <div key={index} className="m-0 px-1 py-0 flex flex-row align-center justify center" >
                <div className="mx-0 my-auto" >
                    <Checkbox id={field.id} value={field.id}  ripple={false} onChange={changeChecked} checked={checked} className="hover:before:opacity-0 w-6 h-6 my-auto " containerProps={{ className: "p-1", }} />
                </div>
                
                    <HorizontalSmallFieldCard img={field.image} name={field.name} area={field.area} crops={field.crops} />
                
                </div>
                </Tooltip>
                )
        }







        return (
            <div className='flex flex-col mx-0 py-1 mb-2 w-full h-full max-h-[20rem] overflow-y-auto border border-blue-300 rounded-md'>
                {fields &&
                fields.map((field,index) => {
                        return (
                            <FieldItem key={index} field={field} fields={fields}/> 
                        )
                    })
                }
            </div>
        )
    }
