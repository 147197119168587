import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import * as Geo from './GeoTools';

import {
  Badge,
  Chip,
  Navbar,
  Collapse,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  ListItemPrefix,
  ListItemSuffix,
  MenuItem,
  Avatar,
  Card,
  IconButton,
  ListItem,
  Tooltip
} from "@material-tailwind/react";

import {
  BriefcaseIcon,
  CubeTransparentIcon,
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  PowerIcon,
  PlusIcon,
  DocumentPlusIcon,
  RocketLaunchIcon,
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  MapIcon,
  PlusCircleIcon,
  Bars2Icon,
  Bars3Icon,
  XMarkIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";

import { HorizontalFieldCard } from "./ViewFieldCard";
import AgroTechnologyLogo from './agro-small.jpg'
import { getCompanies, getFields, getOrders, getMyDocuments, getOffers, getSamplers } from "./ClientTools";

import { OrderItemHorizontal } from "./PreviewOrdersCard";
import OrderCard from "./OrderCard";
import PasswordChange from "./PasswordChange";
import FieldCard from "./FieldCard";
import ClientDocumentsList from "./ClientDocumentsList"

// profile menu component
const profileMenuItems = [
  {
    label: "Zmień hasło",
    icon: UserCircleIcon,
    //onClick: ChangePassword
  },
  {
    label: "Ustawienia",
    icon: Cog6ToothIcon,
    //onClick: ClientSettings
  },
  {
    label: "Wiadomości",
    icon: InboxArrowDownIcon,
  },
  {
    label: "Pomoc",
    icon: LifebuoyIcon,
  },
  {
    label: "Wyloguj się",
    icon: PowerIcon,
    //onClick: ClientLogOff
  },
];




function ClientProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [firstname, setFirstname] = useState(localStorage.getItem("firstname") || null);
  const closeMenu = () => setIsMenuOpen(false);
  const navigate = useNavigate();
  const [openChangePass, setOpenChangePass] = React.useState(false);

  function logout() {
    navigate('/')
  }


  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
          >
            <Typography variant="small" color="gray" className="font-normal normal-case pl-4 pr-2 py-2">
              Witaj {firstname}
            </Typography>
            <UserCircleIcon className="w-6 h-6" />
            <ChevronDownIcon strokeWidth={2.5} className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
            />
          </Button>
        </MenuHandler>
        <MenuList className="p-1">
          <MenuItem key='changepass' onClick={() => setOpenChangePass(true)} className={`flex items-center gap-2 rounded`}>
            <UserCircleIcon className="h-4 w-4" strokeWidth={2} />
            <Typography as="span" variant="small" className="font-normal" color="inherit">
              Zmień hasło
            </Typography>
          </MenuItem>

          <MenuItem key='config' onClick={closeMenu} className={`flex items-center gap-2 rounded`}>
            <Cog6ToothIcon className="h-4 w-4" strokeWidth={2} />
            <Typography as="span" variant="small" className="font-normal" color="inherit">
              Ustawienia
            </Typography>
          </MenuItem>

          <MenuItem key='help' onClick={closeMenu} className={`flex items-center gap-2 rounded `}>
            <LifebuoyIcon className="h-4 w-4" strokeWidth={2} />
            <Typography as="span" variant="small" className="font-normal" color="inherit">
              Pomoc
            </Typography>
          </MenuItem>

          <MenuItem key='logout' onClick={logout} className={`flex items-center gap-2 rounded hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10`}>
            <PowerIcon className="h-4 w-4 text-red-500" strokeWidth={2} />
            <Typography as="span" variant="small" className="font-normal" color="red">
              Wyloguj
            </Typography>
          </MenuItem>


        </MenuList>
        
      </Menu>
      {openChangePass === true ? <PasswordChange close={()=>setOpenChangePass(false)} /> : ''}
    </>
  );
}




export function ClientNavbar() {
  const [isFieldsMenuOpen, setIsFieldsMenuOpen] = React.useState(false);
  const [isCompaniesMenuOpen, setIsCompaniesMenuOpen] = React.useState(false);
  const [isOrdersMenuOpen, setIsOrdersMenuOpen] = React.useState(false);
  const [isNewsMenuOpen, setIsNewsMenuOpen] = React.useState(false);
  const [isDocumentsMenuOpen, setIsDocumentsMenuOpen] = React.useState(false);

  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
  const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);
  const [news, setNews] = useState(JSON.parse(localStorage.getItem("news")) || []);
  const [documents, setDocuments] = useState(JSON.parse(localStorage.getItem("documents")) || []);

  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [offers, setOffers] = useState();


  const [selectedField, setSelectedField] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [userid, setUserId] = useState(localStorage.getItem("userid") || 0);
  const [hasCompanies, setHasCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [all, setAll] = useState([]);
  const [showOrderCard, setShowOrderCard] = useState(false);
  const [showFieldCard, setShowFieldCard] = useState(false);

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  const toggleDocumentsOpen = () => setIsDocumentsMenuOpen((cur) => !cur);

  React.useEffect(() => {
    getCompanies(session, userid, companiesCallback);
    getFields(session, userid, setFields); //pobierz listę pól
    getOrders(session, userid, setOrders); //pobierz listę zadań
    getOffers(session, userid, setOffers); //pobierz listę zadań
    //getNews(session, userid, setNews); //pobierz listę pól
    getMyDocuments(session, userid, setDocuments);

    console.log('init');
    //samplers interval
    const interval = setInterval(() => {
      let lock = localStorage.getItem("lockvehicles") || false;
      //if (lock != "true")
      //getSamplers(session, updateVehicles);
    }, 10000);
    return () => clearInterval(interval);



    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);


  function updateVehicles(vehicles) {
    console.log(vehicles);
    dispatchEvent(new Event("vehiclesChanged"));
  }



  React.useEffect(() => {
    Geo.showFields(fields);
  }, [fields]);


  function companiesCallback(companies) {
    if ((!companies) || (companies.length == 0)) {
      setHasCompanies(false);
      setCompanies([]);
      return;
    }
    setCompanies(companies);
    setHasCompanies(true);
    let all = [userid];
    companies.forEach(comp => {
      all.push(comp.id)
      getFields(session, userid, setFields); //pobierz listę pól
    });
    setAll(all);

  }



  function handleShowOrderCard(e) {
    const id = e.currentTarget.getAttribute("data-orderid");
    setSelectedOrder(id);

    setShowOrderCard(true);
    setIsOrdersMenuOpen(false);
  }


  function handleShowFieldCard(e) {
    const id = e.currentTarget.getAttribute("data-fieldid");
    setSelectedField(id);
    setShowFieldCard(true);
    setIsFieldsMenuOpen(false);
  }


  function orderCardCallback(refresh) {
    setShowOrderCard(false);
    if (refresh) {
      getOrders(session, userid, setOrders); //pobierz listę zadań
    }
  }

  function fieldCardCallback(refresh) {
    setShowFieldCard(false);
    if (refresh) {
      getFields(session, userid, setFields); //pobierz listę pól
    }
  }


  function NewOrderButton() {

    return (
      <Button size="md" variant="gradient" className='px-2 py-1 ml-auto mr-0 w-auto flex flex-row align-middle' color="green" data-orderid={0} onClick={handleShowOrderCard} >
        <DocumentPlusIcon className='w-5 h-5 mr-2' color="white" strokeWidth='1.5' />
        <Typography variant="small" className="text-sm normal-case">Nowe Zlecenie</Typography>
      </Button>
    )
  }


  //ownername={clients.length > 0 && clients.filter(c => c.id == fields.owner)[0].name}
  //img={fields.length > 0 && fields.filter(field => field.id == order.id)[0].image}
  function MobileMenu() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const [fieldListOpen, setFieldListOpen] = React.useState(false);
    const [ordersListOpen, setOrdersListOpen] = React.useState(false);
    const [documentsListOpen, setDocumentsListOpen] = React.useState(false);

    const closeMobileMenu = () => setIsMobileMenuOpen(false);

  

    function MobileFieldList() {
      return (
        <Card className="absolute h-[calc(100vh-8rem)] w-full max-w-[20rem] p-4 shadow-xl top-[5rem] left-0 shadow-blue-gray-900/5 z-10">
          <div className="flex flex-row justify-between w-auto p-0 my-0">
            <Typography color="gray" variant="small" className="font-normal ml-2 mr-auto top-1">
              Twoje Pola
            </Typography>
            <IconButton variant='text' className='-top-[0.5rem]' color="blue-gray" data-refresh={false} onClick={() => setFieldListOpen(false)}>
              <XMarkIcon className='w-6 h-6' />
            </IconButton>
          </div>

          {fields && fields.map((field) => (
            <ListItem key={field.id} className='p-2 m-0' data-fieldid={field.id} data-ownerid={field.owner} onClick={onFieldSelectClick}>
              <HorizontalFieldCard img={field.image} name={field.name} area={field.area} crops={field.crops} />
            </ListItem>))}
        </Card>
      )
    }


    function MobileOrdersList() {
      return (
        <Card className="absolute max-h-[calc(100vh-8rem)] w-full h-auto max-w-[20rem] p-4 shadow-xl top-[6rem] left-0 shadow-blue-gray-900/5 z-10">
          <div className="flex flex-row justify-between w-auto p-0 my-0">
            <Typography color="gray" variant="small" className="font-normal ml-2 mr-auto">
              Twoje zlecenia
            </Typography>
            <IconButton variant='text' className='-top-[1rem]' color="blue-gray" data-refresh={false} onClick={() => setOrdersListOpen(false)}>
              <XMarkIcon className='w-6 h-6' />
            </IconButton>
          </div>
          <RenderOrdersList />
        </Card>
      )
    }



    return (
      <>
      <Menu open={isMobileMenuOpen} handler={setIsMobileMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button variant="text" color="blue-gray" className="flex items-center gap-1 rounded-full px-4 py-2 ml-auto mr-[1rem] lg:hidden">
            <Bars3Icon className="w-6 h-6" />
            <ChevronDownIcon strokeWidth={2.5} className={`h-3 w-3 transition-transform ${isMobileMenuOpen ? "rotate-180" : ""}`} />
          </Button>
        </MenuHandler>
        <MenuList className="p-1 mt-[1rem] ml-[2rem] lg:hidden">

          <MenuItem key="fields" className="flex items-left gap-2 rounded " onClick={() => setFieldListOpen(true)} >
            <MapIcon className="h-[18px] w-[18px]" />
            <Typography as="span" variant="small" className="font-normal mr-auto" color="inherit" >
              Pola
            </Typography>
          </MenuItem>

          <MenuItem key="fields" className="flex items-left gap-2 rounded " onClick={() => setOrdersListOpen(true)}>
            <ClipboardDocumentListIcon className="h-[18px] w-[18px]" />
            <Typography as="span" variant="small" className="font-normal mr-auto" color="inherit" >
              Zlecenia
            </Typography>
          </MenuItem>


          <MenuItem key="fields" className="flex items-left gap-2 rounded ">
            <InboxArrowDownIcon className="h-[18px] w-[18px]" />
            <Typography as="span" variant="small" className="font-normal mr-auto" color="inherit" >
              Wiadomości
            </Typography>
          </MenuItem>

        </MenuList>
        {fieldListOpen && <MobileFieldList />}
        {ordersListOpen && <MobileOrdersList />}
      </Menu>
      
      </>
    );
  }






  function onFieldSelectClick(e) {

    const id = e.currentTarget.getAttribute("data-fieldid");
    setSelectedField(id);
    let bbox = JSON.parse(fields.filter(f => f.id == id)[0].bbox);
    bbox = Geo.resizeBoundingBox(bbox);
    Geo.applyBoundingBox(bbox);
    setShowFieldCard(true);
    setIsFieldsMenuOpen(false);

  }




  function RenderFieldsList() {
    return (
      fields && fields.map((field) => (
        <ListItem key={field.id} className='p-2 m-0' data-fieldid={field.id} data-ownerid={field.owner} onClick={onFieldSelectClick}>
          <HorizontalFieldCard img={field.image} name={field.name} area={field.area} crops={field.crops} />
        </ListItem>)
      )
    )
  }


  function getFieldsArea(fields,selected) {
    if (!selected) return 0;
    let parsedlist=JSON.parse(selected)
    let area_sum=0;
    fields.forEach(field => {
        if (parsedlist.includes(field.id)) { field.selected=true; area_sum+=Number(field.area); }
        else  { field.selected=false; }
    });
    return area_sum.toFixed(2);
}





  function RenderOrdersList() {
    console.log(orders);

    if (orders.length == 0) {
      return (
        <>
          <ListItem key={-1} className='p-2 m-0' data-fieldid={0} data-ownerid={userid} disabled={true}>
            Nie utworzyłeś jeszcze żadnego zlecenia
          </ListItem>
        </>
      )
    }
    else {
      return (
        orders.map((order) => (
          <>
            <ListItem key={order.id} className='p-2 m-0' data-fieldid={order.fieldid} data-orderid={order.id} onClick={handleShowOrderCard}>
              <OrderItemHorizontal fieldname={order.name} crops={fields.filter(f => f.id == order.fieldid)[0].crops} progress={order.progress} status={order.status} area={getFieldsArea(fields,order.fieldlist)} img={fields.filter(f => f.id == order.fieldid)[0].image} />
            </ListItem>
          </>
        )
        ))
    }

  }






  return (
    <>
      <Navbar className="mx-auto w-screen bg-white">

        <div className="relative mx-auto flex items-center text-blue-gray-900">
          
          <a href="https://agrotechnology.pl/" target="_blank">
          <img src={AgroTechnologyLogo} alt="Agro-Technology" className="h-12 flex self-center"  />
          </a>
          <Menu className="float-left ml-auto mr-6">
              <MenuHandler>
                  <Typography variant="small" className="font-normal" as="a" href="https://agrotechnology.pl/" target="_blank">
                    <ListItem className="hidden text-center justify-center gap-2 text-blue-gray-900 w-[6rem] xl:flex xl:rounded-full py-1 text-xs px-2">
                      Sprawdź naszą ofertę
                    </ListItem>
                  </Typography>
                </MenuHandler>
                </Menu>

                
          <div className="absolute top-2/4 left-2/4 hidden flex-row -translate-x-2/4 -translate-y-2/4 lg:block ">
            <div className="mb-2 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">

              {/* Menu Spółki */}
              {hasCompanies==200 &&
                <Menu open={isCompaniesMenuOpen} handler={setIsCompaniesMenuOpen}>
                  <MenuHandler>
                    <Typography variant="small" className="font-normal">
                      <ListItem className="hidden items-center gap-2 text-blue-gray-900 w-[8rem] lg:flex lg:rounded-full py-3 px-2">
                        <BriefcaseIcon className="ml-auto h-[18px] w-[18px]" /> Spółki
                        <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 mr-auto transition-transform ${isCompaniesMenuOpen ? "rotate-180" : ""}`} />
                      </ListItem>
                    </Typography>
                  </MenuHandler>
                  <MenuList className="hidden w-[20em] grid-cols-1 gap-2 overflow-y-auto lg:grid">
                    <RenderCompaniesList />
                  </MenuList>
                </Menu>
              }

              {/* Menu Dokumenty */}

              <Menu >
                <MenuHandler>
                  <Typography variant="small" className="font-normal">
                    <ListItem className="hidden items-center gap-2 text-blue-gray-900 w-[8rem] lg:flex lg:rounded-full py-3 px-2" onClick={toggleDocumentsOpen}>
                      <DocumentIcon className="ml-auto h-[18px] w-[18px]" /> Dokumenty
                      <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 mr-auto transition-transform ${isDocumentsMenuOpen ? "rotate-180" : ""}`} />
                    </ListItem>
                  </Typography>
                </MenuHandler>
                <MenuList className="hidden w-[20em] grid-cols-1 gap-2 overflow-y-auto">
                </MenuList>
              </Menu>


              {/* Menu Pola */}

              <Menu open={isFieldsMenuOpen} handler={setIsFieldsMenuOpen}>
                <MenuHandler>
                  <Typography variant="small" className="font-normal">
                    <ListItem className="hidden items-center gap-2 text-blue-gray-900 w-[8rem] lg:flex lg:rounded-full py-3 px-2">
                      <MapIcon className="ml-auto h-[18px] w-[18px]" /> Pola
                      <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 mr-auto transition-transform ${isFieldsMenuOpen ? "rotate-180" : ""}`} />
                    </ListItem>
                  </Typography>
                </MenuHandler>
                <MenuList className="hidden w-[20em] grid-cols-1 gap-2 overflow-y-auto lg:grid max-h-[84vh]">
                  <RenderFieldsList />
                  <Tooltip content="Dodaj pole">
                  <Button size="md" variant="gradient" className='px-2 py-1 ml-auto mr-0 w-auto flex flex-row align-middle' color="green" data-fieldid={0} onClick={handleShowFieldCard} >
                  <DocumentPlusIcon className='w-5 h-5 mr-2' color="white" strokeWidth='1.5' />
                  <Typography variant="small" className="text-sm normal-case">Nowe pole</Typography>
                  </Button>
                  </Tooltip>

                </MenuList>
              </Menu>

              {/* Menu Zlecenia */}

              <Menu open={isOrdersMenuOpen} handler={setIsOrdersMenuOpen}>
                <MenuHandler>
                  <Typography variant="small" className="font-normal">
                    <ListItem className="hidden items-center gap-1 text-blue-gray-900 w-[10rem] lg:flex lg:rounded-full py-3 px-2">
                      <ClipboardDocumentListIcon className="ml-auto h-[18px] w-[18px]" /> Zlecenia
                      <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 mr-auto transition-transform ${isOrdersMenuOpen ? "rotate-180" : ""}`} />
                    </ListItem>
                  </Typography>
                </MenuHandler>
                <MenuList className="hidden w-[20rem] grid-cols-1 gap-2 overflow-y-auto lg:grid max-h-[84vh]">
                  <RenderOrdersList />
                  <NewOrderButton />
                </MenuList>
              </Menu>


              {/* Menu Wiadomości */}

              <Menu open={isNewsMenuOpen} handler={setIsNewsMenuOpen}>
                <MenuHandler>
                  <Typography variant="small" className="font-normal">
                    <ListItem className="hidden items-center gap-2 text-blue-gray-900 w-[10rem] lg:flex lg:rounded-full py-3 px-2">
                      <InboxArrowDownIcon className="ml-auto h-[18px] w-[18px]" /> Wiadomości
                      <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 mr-auto transition-transform ${isNewsMenuOpen ? "rotate-180" : ""}`} />
                    </ListItem>
                  </Typography>
                </MenuHandler>
                <MenuList className="hidden w-[24rem] grid-cols-1 gap-2 overflow-visible lg:grid">
                  {/* {RenderNewsList} */}
                </MenuList>
              </Menu>

            </div>
          </div>

          <IconButton size="sm" color="blue-gray" variant="text" onClick={toggleIsNavOpen} className="ml-auto mr-2 hidden">
            <Bars3Icon className="h-6 w-6" />
          </IconButton>

          <MobileMenu />


          <ClientProfileMenu />
        </div>
      </Navbar>
      {isDocumentsMenuOpen && <ClientDocumentsList Close={()=>setIsDocumentsMenuOpen(false)}/> }        
      {showOrderCard === true ? <OrderCard close={orderCardCallback} orderid={selectedOrder} clientid={userid} fieldid={selectedField} /> : ''}
      {showFieldCard === true ? <FieldCard close={fieldCardCallback} fieldid={selectedField} clientid={userid} /> : ''}
    </>
  );
}
