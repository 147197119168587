import React, { useState, useRef } from 'react';



import {
    Alert,
    Card,
    Input,
    Checkbox,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemPrefix,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Tooltip,
    Select,
    Option,
    Textarea,

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    TrashIcon,
    EyeIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";



import Datepicker from "react-tailwindcss-datepicker";
import { showAlert } from './Alert.jsx';

import FieldsCheckList from './FieldsCheckList;.jsx';



export default function OrderCard(props) {

    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
    const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);

    const [action, setAction] = useState('create');

    const [fieldid, setFieldId] = useState();
    const [fieldsSelectedList, setFieldsSelectedList] = useState([]);
    const [offerid, setOfferId] = useState();
    const [area, setArea] = useState();
    const [offerPrice, setOfferPrice] = useState();
    const [estimatedValue, setEstimatedValue] = useState(0);
    const [finalValue, setFinalValue] = useState();
    const [value, setValue] = useState();
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [dateValue, setDateValue] = useState({ startDate: null, endDate: null });
    const [orderid, setOrderid] = useState();
    const [progress, setProgress] = useState(1);
    const [status, setStatus] = useState('Zlecenie zgłoszone do realizacji');

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [confirm, setConfirm] = useState(false);


    const [refreshAfterExit, setRefreshAfterExit] = useState(false);


    React.useEffect(() => {

        setFieldId(props.fieldid);
        console.log('props');
        console.log(props);
        if (props.orderid > 0) {
            setAction('update');
            setOrderid(props.orderid);

            let ordersarray = orders.filter(o => o.id == props.orderid);
            if (ordersarray.length == 1) {
                let order = ordersarray[0];
                setOfferId(order.offerid);
                setFieldId(order.fieldid);
                var selected=JSON.parse(order.fieldlist);
                console.log("selected are");
                console.log(selected);
                setFieldsSelectedList(selected);
                
                let area_sum=0;
                fields.forEach(field => {
                    if (selected.includes(field.id)) { field.selected=true; field.onstart=true; area_sum+=Number(field.area); }
                    else  { field.selected=false; }
                });
                
                setArea(getFieldArea(area_sum));
                setDescription(order.description);

                setFinalValue(order.finalvalue);
                setProgress(order.progress);
                setStatus(order.status);
                if (order.finalvalue > 0) { setValue(order.finalvalue) }
                else { setValue(order.estimatedvalue); };

                setDateValue(JSON.parse(order.proposaldate));
            }
        }
        else {
            if (offers.length > 0) {
                setOfferId(offers[0].id);
                setOfferPrice(offers[0].price);
            }
            /*
            if (props.fieldid > 0) {
                let field = fields.filter(f => f.id == fieldid)[0];
                if (field) setArea(field.area);
            }
            else {
                if (fields.length > 0)
                    setFieldId(fields[0].id);
                setArea(fields[0].area);
            }
            */    

        }

    }, []);




    function getFieldArea(fid) {
        let area_sum=0;
        fields.forEach(field => {
            if (fieldsSelectedList.includes(field.id)) { field.selected=true; area_sum+=Number(field.area); }
            
        });
        return area_sum;
}



    function cancelClose() {
        props.close(refreshAfterExit);
    }


    function handleDataResponse(data) {

        console.log(data)

        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }



    function deleteOrder() {
        let bodydata = JSON.stringify({ action: 'remove', session: session, id: orderid, fieldid: fieldid });
        console.log(bodydata);

        setConfirm(false);

        fetch('https://agro-online.pl/cgi-bin/orders.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }




    function handleSubmitField(e) {
        e.preventDefault();
        var name=getSelectedFieldsName();
        var selected=getSelectedFieldsIds();
        setFieldsSelectedList(selected);
        
        console.log('fields on send');
        console.log(fields);
        console.log('selected');
        console.log(selected);


        let bodydata = JSON.stringify({ action: action, session: session, name: name, description: description, id: orderid, proposaldate: JSON.stringify(dateValue), fieldid: fieldid, offerid: offerid, offerprice: offerPrice, progress: progress, status: status, estimatedvalue: estimatedValue, finalvalue: finalValue, fieldlist: JSON.stringify(selected) });

        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/orders.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }



    function getSelectedFieldsIds() {
        var selectedarray = [];
        var selected=fields.filter((field) => field.selected===true);
        
        selected.forEach(element => {
            selectedarray.push(Number(element.id));
        });
        
        
        return selectedarray;
    }



    function getSelectedFieldsArea() {
        var area = 0;
        var selected=fields.filter((field) => field.selected==true);

        selected.forEach(element => {
            area += Number(element.area);    
        });
        
        console.log("total area is " + area);
        return area;
    }

    function getSelectedFieldsName() {
        
        var selected=fields.filter((field) => field.selected==true);
        var name="";
        if (selected.length==1) name=selected[0].name;
        if (selected.length>1) name="Wiele pól (" + selected.length +")";

        
        return name;
    }




    // Return classes based on whether item is checked
    function isFieldChecked(item) {
        let result = fieldsSelectedList.includes(item);
        return (result);
    }


    function onSelectFieldChange(e) {
        console.log(e);
        setFieldId(e);
        setArea(getFieldArea(e));
    }

    function onSelectOfferChange(e) {
        console.log(e);
        setOfferId(e);
        let selected = offers.filter(off => off.id == e)[0];
        if (selected) setOfferPrice(selected.price);
    }


    function descriptionChange(e) {
        setDescription(e.target.value);
    }


    function handleDateChange(value) {
        console.log("value:", value);
        setDateValue(value);
    }




    return (
        <div className='absolute w-full h-full top-0 left-0 items-center justify-cener m-auto flex'>
            <Card color="white" className="w-full w-[20rem] m-auto top-[6rem] py-4 shrink z-20 shadow-xl max-h-[calc(100vh-7rem)] overflow-y-none" shadow={true}>

                <div className="flex items-center justify-between gap-4 w-auto my-0">
                    <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
                        Karta zlecenia
                    </Typography>
                    <IconButton variant='text' className='top-[-10px] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <form className="mt-1  mx-auto w-full sm:w-80 items-center justify-center py-1 px-2 gap-4" onSubmit={handleSubmitField}>
                    <div className="mb-2 flex flex-col gap-3 w-auto items-center justify-center">

                        <Typography variant="small" label="Areał" color="gray" className="w-full text-center mx-auto "  >Wybierz pola do zlecenia</Typography>
                        <FieldsCheckList selected={fieldsSelectedList} fields={fields} />

                        <Select label="Zakres badania " value={offerid > 0 ? offerid.toString() : ''} onChange={onSelectOfferChange}>
                            {
                                offers.map((value) => (
                                    <Option key={value.id} value={value.id.toString()}>{value.name}</Option>
                                ))
                            }
                        </Select>

                        <Datepicker i18n={"pl"} primaryColor="green" value={dateValue} onChange={handleDateChange} inputClassName="w-full border rounded-md py-2 px-2 border-gray-400 text-gray-600 text-normal focus:border-blue-500 focus:outline-0 focus:ring-0" placeholder="Proponowana data realizacji" ></Datepicker>

                        {(finalValue > 0) && <Typography variant="small" label="Areał" className="text-center" >Całkowity koszt : {finalValue} zł</Typography>}

                        <Textarea label="Uwagi" value={description} onChange={descriptionChange} />

                        <div className='flex flex-row justify-between w-full mt-4' >
                            <IconButton variant='gradient' className='ml-0' color="blue" data-refresh={false} onClick={() => setConfirm(true)} >
                                <EyeIcon className='w-6 h-6' />
                            </IconButton>

                            <Button className="items-center  mr-0 w-40 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 " data-refresh={true} fullWidth type="submit">
                                Zapisz
                            </Button>
                        </div>
                    </div>

                </form>
            </Card>


            <Dialog open={confirm} size={"xs"} handler={setConfirm}>
                <DialogHeader>Jesteś pewien ?</DialogHeader>
                <DialogBody divider>
                    Czy na pewno chcesz usunąć to zlecenie
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setConfirm(false)}
                        className="mr-8"
                    >
                        <span>Nie</span>
                    </Button>
                    <Button
                        variant="gradient"
                        color="green"
                        onClick={() => deleteOrder()}
                    >
                        <span>Tak</span>
                    </Button>
                </DialogFooter>
            </Dialog>


        </div>
    );


}

